import { withRouter } from "next/router"
import { TopNavigationClick } from "../../../utils/gtm"

function ActiveLink({
  children,
  href,
  classList,
  router,
  blackCollor,
  setIsHamburgerOpen,
}) {
  (function prefetchPages() {
    if (typeof window !== "undefined") {
      router.prefetch(router.pathname)
    }
  })()

  const handleClick = e => {
    e.preventDefault()
    TopNavigationClick(href)
    router.push(href)
    if (setIsHamburgerOpen) setIsHamburgerOpen(false)
  }

  const isCurrentPath =
    router.pathname === href ||
    router.asPath === href ||
    router.pathname.includes(href) ||
    router.asPath.includes(href)

  return (
    <a
      href={href}
      className={`${classList} block font-noto-bold ${
        blackCollor
          ? "text-black"
          : `${isCurrentPath ? "text-pink-500" : "text-black"}`
      }`}
      onClick={handleClick}
    >
      {children}
    </a>
  )
}

export default withRouter(ActiveLink)
